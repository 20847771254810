export const DOOMFIST = "doomfist";
export const DVA = "dva";
export const JUNKER_QUEEN = "junker_queen";
export const MAUGA = "mauga";
export const ORISA = "orisa";
export const RAMATTRA = "ramattra";
export const REINHARDT = "reinhardt";
export const ROADHOG = "roadhog";
export const SIGMA = "sigma";
export const WINSTON = "winston";
export const WRECKING_BALL = "wrecking_ball";
export const ZARYA = "zarya";

export const TANK_ARRAY = [DOOMFIST, DVA, JUNKER_QUEEN, MAUGA, ORISA, RAMATTRA, REINHARDT, ROADHOG, SIGMA, WINSTON, WRECKING_BALL, ZARYA];
export const GROUND_TANK_ARRAY = [JUNKER_QUEEN, MAUGA, ORISA, RAMATTRA, REINHARDT, ROADHOG, SIGMA, ZARYA];
export const SKY_TANK_ARRAY = [DOOMFIST, DVA, WINSTON, WRECKING_BALL];

export const ASHE = "ashe";
export const BASTION = "bastion";
export const CASSIDY = "cassidy";
export const ECHO = "echo";
export const GENJI = "genji";
export const HANZO = "hanzo";
export const JUNKRAT = "junkrat";
export const MEI = "mei";
export const PHARAH = "pharah";
export const REAPER = "reaper";
export const SOJOURN = "sojourn";
export const SOLDIER_76 = "soldier_76";
export const SOMBRA = "sombra";
export const SYMMETRA = "symmetra";
export const TORBJORN = "torbjorn";
export const TRACER = "tracer";
export const VENTURE = "venture";
export const WIDOWMAKER = "widowmaker";

export const DAMAGE_ARRAY = [ASHE, BASTION, CASSIDY, ECHO, GENJI, HANZO, JUNKRAT, MEI, PHARAH, REAPER, SOJOURN, SOLDIER_76, SOMBRA, SYMMETRA, TORBJORN, TRACER, VENTURE, WIDOWMAKER];
export const MAIN_DAMAGE_ARRAY = [ASHE, BASTION, CASSIDY, HANZO, SOJOURN, SOLDIER_76, WIDOWMAKER];
export const NORMAL_SUB_DAMAGE_ARRAY = [ECHO, GENJI, JUNKRAT, PHARAH, SOMBRA, TRACER, VENTURE];
export const RUSH_SUB_DAMAGE_ARRAY = [MEI, REAPER, SYMMETRA, TORBJORN];

export const ANA = "ana";
export const BAPTISTE = "baptiste";
export const BRIGITTE = "brigitte";
export const ILLARI = "illari";
export const JUNO = "juno";
export const KIRIKO = "kiriko";
export const LIFE_WEAVER = "life_weaver";
export const LUCIO = "lucio";
export const MERCY = "mercy";
export const MOIRA = "moira";
export const ZENYATTA = "zenyatta";

export const SUPPORT_ARRAY = [ANA, BAPTISTE, BRIGITTE, ILLARI, JUNO, KIRIKO, LIFE_WEAVER, LUCIO, MERCY, MOIRA, ZENYATTA];
export const RUSH_SUPPORT_ARRAY = [JUNO, LUCIO];
export const MAIN_SUPPORT_ARRAY = [ANA, BAPTISTE, KIRIKO, MOIRA]; // 유지력 기준
export const SUB_SUPPORT_ARRAY = [BRIGITTE, ILLARI, LIFE_WEAVER, MERCY, ZENYATTA]; // 유지력 기준

export const HERO_ARRAY = [DOOMFIST, DVA, JUNKER_QUEEN, MAUGA, ORISA, RAMATTRA, REINHARDT, ROADHOG, SIGMA, WINSTON, WRECKING_BALL, ZARYA, 
    ASHE, BASTION, CASSIDY, ECHO, GENJI, HANZO, JUNKRAT, MEI, PHARAH, REAPER, SOJOURN, SOLDIER_76, SOMBRA, SYMMETRA, TORBJORN, TRACER, VENTURE, WIDOWMAKER, 
    ANA, BAPTISTE, BRIGITTE, ILLARI, JUNO, KIRIKO, LIFE_WEAVER, LUCIO, MERCY, MOIRA, ZENYATTA]