import { DAMAGE_ARRAY, GROUND_TANK_ARRAY, MAIN_DAMAGE_ARRAY, MAIN_SUPPORT_ARRAY, NORMAL_SUB_DAMAGE_ARRAY, RUSH_SUB_DAMAGE_ARRAY, RUSH_SUPPORT_ARRAY, SKY_TANK_ARRAY, SUB_SUPPORT_ARRAY, SUPPORT_ARRAY, TANK_ARRAY } from "../properties/hero";
import { STATISTIC } from "../properties/statistic";

export const getRecommand = (role, map, teamHeroes, enemyHeroes) => {
    let roleArray = undefined;
    if (role === "tank") {
        roleArray = TANK_ARRAY;
    } else if (role === "damage") {
        roleArray = DAMAGE_ARRAY;
    } else if (role === "support") {
        roleArray = SUPPORT_ARRAY;
    }

    let frameData = undefined;
    const keys = [];

    if (!isEmpty(STATISTIC["frame_pick_rate"][map])) {
        frameData = STATISTIC["frame_pick_rate"][map]
        for (let key of Object.keys(frameData)) {
            const keySplit = key.split("-");
            if (role === "tank") {
                if (!((getHeroType(teamHeroes[0]) === keySplit[0] && getHeroType(teamHeroes[1]) === keySplit[1]) || (getHeroType(teamHeroes[0]) === keySplit[1] && getHeroType(teamHeroes[1]) === keySplit[0]))) {
                    continue
                }
    
                if (!((getHeroType(teamHeroes[2]) === keySplit[3] && getHeroType(teamHeroes[3]) === keySplit[4]) || (getHeroType(teamHeroes[2]) === keySplit[4] && getHeroType(teamHeroes[3]) === keySplit[3]))) {
                    continue;
                }
    
                keys.push(key);
            } else if (role === "damage") {
                if (getHeroType(teamHeroes[0]) !== keySplit[2]) {
                    continue;
                }
    
                if (!keySplit.includes(getHeroType(teamHeroes[1]))) {
                    continue;
                }
    
                if (!((getHeroType(teamHeroes[2]) === keySplit[3] && getHeroType(teamHeroes[3]) === keySplit[4]) || (getHeroType(teamHeroes[2]) === keySplit[4] && getHeroType(teamHeroes[3]) === keySplit[3]))) {
                    continue;
                }
    
    
                keys.push(key);
            } else if (role === "support") {
                if (getHeroType(teamHeroes[0]) !== keySplit[2]) {
                    continue;
                }
    
                if (!((getHeroType(teamHeroes[1]) === keySplit[0] && getHeroType(teamHeroes[2]) === keySplit[1]) || (getHeroType(teamHeroes[1]) === keySplit[1] && getHeroType(teamHeroes[2]) === keySplit[0]))) {
                    continue
                }
    
                if (!keySplit.includes(getHeroType(teamHeroes[3]))) {
                    continue
                }
    
                keys.push(key);
            }
        }
    }


    if (keys.length === 0) {
        frameData = STATISTIC["frame_pick_rate"]["total"];
        for (let key of Object.keys(frameData)) {
            const keySplit = key.split("-");
            if (role === "tank") {
                if (!((getHeroType(teamHeroes[0]) === keySplit[0] && getHeroType(teamHeroes[1]) === keySplit[1]) || (getHeroType(teamHeroes[0]) === keySplit[1] && getHeroType(teamHeroes[1]) === keySplit[0]))) {
                    continue
                }
    
                if (!((getHeroType(teamHeroes[2]) === keySplit[3] && getHeroType(teamHeroes[3]) === keySplit[4]) || (getHeroType(teamHeroes[2]) === keySplit[4] && getHeroType(teamHeroes[3]) === keySplit[3]))) {
                    continue;
                }
    
                keys.push(key);
            } else if (role === "damage") {
                if (getHeroType(teamHeroes[0]) !== keySplit[2]) {
                    continue;
                }
    
                if (!keySplit.includes(getHeroType(teamHeroes[1]))) {
                    continue;
                }
    
                if (!((getHeroType(teamHeroes[2]) === keySplit[3] && getHeroType(teamHeroes[3]) === keySplit[4]) || (getHeroType(teamHeroes[2]) === keySplit[4] && getHeroType(teamHeroes[3]) === keySplit[3]))) {
                    continue;
                }
    
    
                keys.push(key);
            } else if (role === "support") {
                if (getHeroType(teamHeroes[0]) !== keySplit[2]) {
                    continue;
                }
    
                if (!((getHeroType(teamHeroes[1]) === keySplit[0] && getHeroType(teamHeroes[2]) === keySplit[1]) || (getHeroType(teamHeroes[1]) === keySplit[1] && getHeroType(teamHeroes[2]) === keySplit[0]))) {
                    continue
                }
    
                if (!keySplit.includes(getHeroType(teamHeroes[3]))) {
                    continue
                }
    
                keys.push(key);
            }
        }
    }

    const points = {}
    for (const hero of roleArray) {
        points[hero] = 0;
    }

    const pickDataBias = 2;
    const pickData = isEmpty(STATISTIC["pick_rate"][map], roleArray) ? STATISTIC["pick_rate"]["total"] : STATISTIC["pick_rate"][map];
    if (keys.length === 0) {
        for (const hero of Object.keys(pickData)) {
            if (teamHeroes.includes(hero)) {
                continue;
            }

            if (roleArray.includes(hero)) {
                points[hero] += pickData[hero] * pickDataBias;
            }
        }
    } else {
        let frame = keys[0];
        for (const hero of teamHeroes) {
            frame = frame.replace(getHeroType(hero), "");
        }

        frame = frame.replaceAll("-", "");

        for (const hero of Object.keys(pickData)) {
            if (teamHeroes.includes(hero)) {
                continue;
            }

            if (!roleArray.includes(hero)) {
                continue;
            }

            if (getHeroType(hero) === frame) {
                points[hero] += pickData[hero] * pickDataBias;
            }
        }
    }

    const counterBias = 1;
    const counterData = STATISTIC["counter"];
    for (const hero of roleArray) {
        const heroData = counterData[hero];
        for (const targetHero of enemyHeroes) {
            if (Number.isNaN(targetHero)) {
                continue;
            }

            points[hero] += heroData[targetHero] * counterBias;
        }
    }
      
      const sortedPoint = Object.fromEntries(Object.entries(points).sort(([, a], [, b]) => b - a));
      return Object.keys(sortedPoint)[0];
}

function getHeroType(hero) {
    if (GROUND_TANK_ARRAY.includes(hero)) {
        return "ground_tank";
    } else if (SKY_TANK_ARRAY.includes(hero)) {
        return "sky_tank";
    } else if (MAIN_DAMAGE_ARRAY.includes(hero)) {
        return "main_damage";
    } else if (RUSH_SUB_DAMAGE_ARRAY.includes(hero)) {
        return "rush_sub_damage";
    } else if (NORMAL_SUB_DAMAGE_ARRAY.includes(hero)) {
        return "normal_sub_damage";
    } else if (SUB_SUPPORT_ARRAY.includes(hero)) {
        return "sub_support";
    } else if (MAIN_SUPPORT_ARRAY.includes(hero)) {
        return "main_support";
    } else if (RUSH_SUPPORT_ARRAY.includes(hero)) {
        return "rush_support";
    }
}

function isEmpty(obj, keys = 0) {
    if (keys === 0) {
        for (let value of Object.values(obj)) {
            if (value !== 0) {
                return false;
            }
        }
    
        return true;
    } else {
        for (let key of keys) {
            if (obj[key] !== 0) {
                return false;
            }
        }

        return true;
    }

    
}