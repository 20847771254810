import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Hero from './pages/hero';
import Recommandation from './pages/recommandation';

class App extends Component {
  render() {
    return (
      <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/hero" />} />
            <Route path="/hero" element={<Hero />} />
            <Route path="/recommandation" element={<Recommandation />} />
          </Routes>
      </Router>
    );
  }
}

export default App;
