// Control
export const ANTARCTIC_PENINSULA_ICEBREAKER = "antarctic_peninsula_icebreaker"; // 남극반도 쇄빙선
export const ANTARCTIC_PENINSULA_LABS = "antarctic_peninsula_labs"; // 남극반도 연구소
export const ANTARCTIC_PENINSULA_SUBLEVEL = "antarctic_peninsula_sublevel"; // 남극반도 지하

export const BUSAN_DOWNTOWN = "busan_downtown"; // 부산 시내
export const BUSAN_MEKA_BASE = "busan_meka_base"; // 부산 MEKA 기지
export const BUSAN_SANCTUARY = "busan_sanctuary"; // 부산 사찰

export const ILIOS_LIGHTHOUSE = "ilios_lighthouse"; // 일리오스 등대
export const ILIOS_RUINS = "ilios_ruins"; // 일리오스 폐허
export const ILIOS_WELL = "ilios_well"; // 일리오스 우물

export const LIJIANG_TOWER_CONTROL_CENTER = "lijiang_tower_control_center"; // 리장 타워 관제 센터
export const LIJIANG_TOWER_GARDEN = "lijiang_tower_garden"; // 리장 타워 정원
export const LIJIANG_TOWER_NIGHT_MARKET = "lijiang_tower_night_market"; // 리장 타워 야시장

export const NEPAL_SANCTUM = "nepal_sanctum"; // 네팔 성소
export const NEPAL_SHRINE = "nepal_shrine"; // 네팔 제단
export const NEPAL_VILLAGE = "nepal_village"; // 네팔 마을

export const OASIS_CITY_CENTER = "oasis_city_center"; // 오아시스 도심
export const OASIS_GARDENS = "oasis_gardens"; // 오아시스 정원
export const OASIS_UNIVERSITY = "oasis_university"; // 오아시스 대학

export const SAMOA_BEACH = "samoa_beach"; // 사모아 해변
export const SAMOA_DOWNTOWN = "samoa_downtown"; // 사모아 시내
export const SAMOA_VOLCANO = "samoa_volcano"; // 사모아 화산

// Escort
export const CIRCUIT_ROYAL = "circuit_royal"; // 서킷 로얄
export const DORADO = "dorado"; // 도라도
export const HAVANA = "havana"; // 하바나
export const JUNKERTOWN = "junkertown"; // 쓰레기촌
export const RIALTO = "rialto"; // 리알토
export const ROUTE_66 = "route_66"; // 66번 국도
export const SHAMBALI_MONASTERY = "shambali_monastery"; // 샴발리 수도원
export const WATCHPOINT_GIBRALTAR = "watchpoint_gibraltar"; // 감시 기지 지브롤터

// Flashpoint
export const NEW_JUNK_CITY = "new_junk_city"; // 뉴 정크 시티
export const SURAVASA = "suravasa"; // 수라바사

// Hybrid
export const BLIZZARD_WORLD = "blizzard_world"; // 블리자드 월드
export const EICHENWALDE = "eichenwalde"; // 아이헨발데
export const HOLLYWOOD = "hollywood"; // 할리우드
export const KINGS_ROW = "kings_row"; // 왕의 길
export const MIDTOWN = "midtown"; // 미드타운
export const NUMBANI = "numbani"; // 눔바니
export const PARAISO = "paraiso"; // 파라이수

// Push
export const COLOSSEO = "colosseo"; // 콜로세오
export const ESPERANCA = "esperanca"; // 이스페란사
export const NEW_QUEEN_STREET = "new_queen_street"; // 뉴 퀸 스트리트
export const RUNASAPI = "runasapi"; // 루나사피

export const MAP_ARRAY = [
    ANTARCTIC_PENINSULA_ICEBREAKER, ANTARCTIC_PENINSULA_LABS, ANTARCTIC_PENINSULA_SUBLEVEL,
    BUSAN_DOWNTOWN, BUSAN_MEKA_BASE, BUSAN_SANCTUARY,
    ILIOS_LIGHTHOUSE, ILIOS_RUINS, ILIOS_WELL,
    LIJIANG_TOWER_CONTROL_CENTER, LIJIANG_TOWER_GARDEN, LIJIANG_TOWER_NIGHT_MARKET,
    NEPAL_SANCTUM, NEPAL_SHRINE, NEPAL_VILLAGE,
    OASIS_CITY_CENTER, OASIS_GARDENS, OASIS_UNIVERSITY,
    SAMOA_BEACH, SAMOA_DOWNTOWN, SAMOA_VOLCANO,
    CIRCUIT_ROYAL, DORADO, HAVANA, JUNKERTOWN, RIALTO, ROUTE_66, SHAMBALI_MONASTERY, WATCHPOINT_GIBRALTAR,
    NEW_JUNK_CITY, SURAVASA,
    BLIZZARD_WORLD, EICHENWALDE, HOLLYWOOD, KINGS_ROW, MIDTOWN, NUMBANI, PARAISO,
    COLOSSEO, ESPERANCA, NEW_QUEEN_STREET, RUNASAPI
];