import { Component, Fragment } from "react";
import "./recommandation.css";
import { useNavigate } from "react-router-dom";
import { DAMAGE_ARRAY, SUPPORT_ARRAY, TANK_ARRAY } from "../properties/hero.js"
import { MAP_ARRAY } from "../properties/map.js"
import { getRecommand } from "./recommand.js";

function withNavigate(Component) {
    return function (props) {
      const navigate = useNavigate();
      return <Component {...props} navigate={navigate} />;
    };
}

class Recommandation extends Component {
    map = MAP_ARRAY[0]

    role = "tank";
    teamHeroes = [DAMAGE_ARRAY[0], DAMAGE_ARRAY[1], SUPPORT_ARRAY[0], SUPPORT_ARRAY[1]];
    enemyHeroes = [NaN, NaN, NaN, NaN, NaN];

    lastClickedTeam = undefined;
    lastClickedSlot = undefined;

    loadMapPopup = () => {
        for (let i = 0; i < MAP_ARRAY.length; i++) {
            const temp = i;
            const map = MAP_ARRAY[temp];
        
            const mapDiv = document.createElement("div");
            mapDiv.className = "map";
        
            const mapImg = document.createElement("img");
            mapImg.className = "map_image";
            mapImg.src = process.env.PUBLIC_URL + "/images/maps/" + map + ".webp";
        
            const mapFilter = document.createElement("div");
            mapFilter.className = "map_filter";
        
            const mapName = document.createElement("p");
            mapName.className = "map_name";
            mapName.innerText = map.replaceAll("_", " ").toUpperCase();
        
            mapDiv.appendChild(mapImg);
            mapDiv.appendChild(mapFilter);
            mapDiv.appendChild(mapName);
        
            document.getElementById("popup_map_section").appendChild(mapDiv);
            mapDiv.onclick = () => this.setMap(map);
        }
    }

    openMapPopup = () => {
        document.getElementById("popup_overlay").style.display = "block";
        document.getElementById("map_popup").style.display = "inline-block";
    }

    setMap = (map) => {
        this.map = map;
        document.getElementById("map_text").textContent = this.map.replaceAll("_", " ").toUpperCase();
        document.getElementById("background_map_image").style.backgroundImage = 'url("/images/maps/' + this.map + '.webp")';
        this.closePopup();

        this.teamHeroes = [DAMAGE_ARRAY[0], DAMAGE_ARRAY[1], SUPPORT_ARRAY[0], SUPPORT_ARRAY[1]];
        this.enemyHeroes = [NaN, NaN, NaN, NaN, NaN];
        this.reloadAlly();
        this.reloadEnemy();
        this.getPlayerRole();
        this.showRecomand();
    }

    getPlayerRole = () => {
        let team = ""
        for (let i = 0; i < 4; i++) {
            const hero = this.teamHeroes[i];
            if (TANK_ARRAY.includes(hero)) {
                team += "t";
            } else if (DAMAGE_ARRAY.includes(hero)) {
                team += "d";
            } else if (SUPPORT_ARRAY.includes(hero)) {
                team += "s";
            }

            if (i < 3) {
                team += "-"
            }
        }
        
        if (team === "d-d-s-s") {
            this.role = "tank";
        } else if (team === "t-d-s-s") {
            this.role = "damage";
        } else if (team === "t-d-d-s") {
            this.role = "support";
        }
    }

    addHeroesToPopup = (role, team) => {
        let heroes = undefined;

        switch (role) {
            case "tank": 
                heroes = TANK_ARRAY;
                break;

            case "damage": 
                heroes = DAMAGE_ARRAY;
                break;

            case "support": 
                heroes = SUPPORT_ARRAY;
                break;

            default: 
                console.log("recommmandation.js, addHeroesToPopup, Unknown role : " + role);
                return ;
        }

        for (let i = 0; i < heroes.length; i++) {
            const temp = i;
            const hero = heroes[temp];

            if (team === "team" && this.teamHeroes.includes(hero)) {
                continue;
            }

            if (team === "enemy" && this.enemyHeroes.includes(hero)) {
                continue;
            }
        
            const heroDiv = document.createElement("div");
            heroDiv.className = "hero_image"
            heroDiv.style.backgroundImage = 'url("/images/heroes/' + hero + '.webp")';
            heroDiv.onclick = () => { this.setHero(hero) }
        
            const heroSection = document.getElementById("popup_hero_section");
            heroSection.appendChild(heroDiv);
    
            if (heroSection.childElementCount % 8 === 0) {
                heroDiv.style.marginRight = "16px";
            }
        }
    }

    setHero = (hero) => {
        if (this.lastClickedTeam === "team") {
            this.teamHeroes[this.lastClickedSlot] = hero;
            this.reloadAlly();
        } else if (this.lastClickedTeam === "enemy") {
            this.enemyHeroes[this.lastClickedSlot] = hero;
            this.reloadEnemy();
        }

        this.closePopup();
        this.getPlayerRole();
        this.showRecomand();
    }

    showRecomand = () => {
        const hero = getRecommand(this.role, this.map, this.teamHeroes, this.enemyHeroes);
        document.getElementById("recommandation_card").style.backgroundImage = 'url("/images/heroes/' + hero + '.webp")';
    }

    openHeroPopup = () => {
        document.getElementById("popup_overlay").style.display = "block";
        document.getElementById("hero_popup").style.display = "inline-block";
    }

    closePopup = () => {
        document.getElementById("popup_overlay").style.display = "none";
        document.getElementById("map_popup").style.display = "none";
        document.getElementById("hero_popup").style.display = "none";
        document.getElementById("popup_hero_section").innerHTML = "";
    }

    reloadAlly = () => {
        for (let i = 1; i <= 4; i++) {
            const hero = this.teamHeroes[i - 1];
            document.getElementById("team_" + i + "_card").style.backgroundImage = 'url("/images/heroes/' + hero + '.webp")';
        }
    }

    reloadEnemy = () => {
        for (let i = 1; i <= 5; i++) {
            const hero = this.enemyHeroes[i - 1];
            if (Number.isNaN(hero)) {
                continue;
            }

            document.getElementById("enemy_" + i + "_card").style.backgroundImage = 'url("/images/heroes/' + hero + '.webp")';
        }
    }

    openPage = (page) => {
        this.props.navigate(page);
    }

    state = {
        arrowHeroRecommanded: "images/arrow_down.svg"
    };

    isHeroRecommanded = false;
    transHeroRecommanded = () => {
        if (this.isHeroRecommanded) {
            document.getElementById("text_how_hero_recommanded_description").style.display = "none";
            this.setState({arrowHeroRecommanded: "./images/arrow_down.svg"})
        } else {
            document.getElementById("text_how_hero_recommanded_description").style.display = "block";
            this.setState({arrowHeroRecommanded: "./images/arrow_up.svg"})
        }
        this.isHeroRecommanded = !this.isHeroRecommanded;
    }

    componentDidMount() {
        document.getElementById("nav_text_hero").style.color = "#B4B4B4";
        document.getElementById("nav_text_recommandation").style.color = "#F5F5F5";
        document.getElementById("map_text").textContent = this.map.replaceAll("_", " ").toUpperCase();
        document.getElementById("background_map_image").style.backgroundImage = 'url("/images/maps/' + this.map + '.webp")';
        this.reloadAlly();
        this.getPlayerRole();
        this.showRecomand();
        this.loadMapPopup();
    }

    render() {
        return (
            <Fragment>
                <div id="title">
                    <p id="title_text" onClick={() => this.openPage("/hero")}>WatchOver.pro</p>
                </div>

                <div id="nav">
                    <div id="nav_box">
                        <p id="nav_text_hero" className="nav_text" onClick={() => this.openPage("/hero")}>Hero</p>
                        <p id="nav_text_recommandation" className="nav_text" onClick={() => this.openPage("/recommandation")}>Recommandation</p>
                    </div>
                </div>

                <div id="map_image_box">
                    <div id="background_map_image"></div>
                </div>

                <div id="content_section">
                    <p id="map_text" onClick={this.openMapPopup}>MAP</p>

                    <div id="comp_section">
                        <div id="team_section">
                            <div id="team_1_card" className="team_card" onClick={() => {this.addHeroesToPopup("tank", "team"); 
                                if (!(DAMAGE_ARRAY.includes(this.teamHeroes[1]) && DAMAGE_ARRAY.includes(this.teamHeroes[2]))) {
                                    this.addHeroesToPopup("damage", "team"); 
                                }
                                this.openHeroPopup(); this.lastClickedTeam = "team"; this.lastClickedSlot = 0;}}></div>
                            <div id="team_2_card" className="team_card" onClick={() => {this.addHeroesToPopup("damage", "team"); this.openHeroPopup(); this.lastClickedTeam = "team"; this.lastClickedSlot = 1;}}></div>
                            <div id="team_3_card" className="team_card" onClick={() => {
                                if (!(DAMAGE_ARRAY.includes(this.teamHeroes[0]) && DAMAGE_ARRAY.includes(this.teamHeroes[1]))) {
                                    this.addHeroesToPopup("damage", "team")
                                }
                                this.addHeroesToPopup("support", "team"); this.openHeroPopup(); this.lastClickedTeam = "team"; this.lastClickedSlot = 2;}}></div>
                            <div id="team_4_card" className="team_card" onClick={() => {this.addHeroesToPopup("support", "team"); this.openHeroPopup(); this.lastClickedTeam = "team"; this.lastClickedSlot = 3;}}></div>
                        </div>
                        <div id="enemy_section">
                            <div id="enemy_1_card" className="enemy_card" onClick={() => {this.addHeroesToPopup("tank", "enemy"); this.openHeroPopup(); this.lastClickedTeam = "enemy"; this.lastClickedSlot = 0;}}></div>
                            <div id="enemy_2_card" className="enemy_card" onClick={() => {this.addHeroesToPopup("damage", "enemy"); this.openHeroPopup(); this.lastClickedTeam = "enemy"; this.lastClickedSlot = 1;}}></div>
                            <div id="enemy_3_card" className="enemy_card" onClick={() => {this.addHeroesToPopup("damage", "enemy"); this.openHeroPopup(); this.lastClickedTeam = "enemy"; this.lastClickedSlot = 2;}}></div>
                            <div id="enemy_4_card" className="enemy_card" onClick={() => {this.addHeroesToPopup("support", "enemy"); this.openHeroPopup(); this.lastClickedTeam = "enemy"; this.lastClickedSlot = 3;}}></div>
                            <div id="enemy_5_card" className="enemy_card" onClick={() => {this.addHeroesToPopup("support", "enemy"); this.openHeroPopup(); this.lastClickedTeam = "enemy"; this.lastClickedSlot = 4;}}></div>
                        </div>
                    </div>

                    <div id="recommandation_card" className="recommandation_card"></div>
                </div>

                <div id="popup_overlay" onClick={this.closePopup}></div>

                <div id="map_popup">
                    <div id="popup_map_section"></div>
                </div>

                <div id="hero_popup">
                    <div id="popup_hero_section"></div>
                </div>

                <div id="page_information_section">
                    <div id="how_hero_recommanded">
                        <div className="question" onClick={this.transHeroRecommanded}>
                            <img id="arrow_how_hero_recommanded" className="arrow" src={this.state.arrowHeroRecommanded}  alt=""></img>
                            <p id="text_how_hero_recommanded" className="question_text">How is hero recommended?</p>
                        </div>

                        <p id="text_how_hero_recommanded_description" className="answer_text">We recommend hero based on professional Overwatch players' matches. We record pick rates, K/D, and hero matchups for each map and combine this data to suggest heroes. The match data we have collected is as follows:<br /><br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY1 MATCH1<br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY1 MATCH2<br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY2 MATCH1<br />
                        - OWCS KOREA STAGE2 GRANDFINALS<br /></p>
                        
                    </div>

                    <p id="site_description_text">
                    Contact : contact.watchover.pro@gmail.com | We value the rights of others. If there is any issue, please contact us. <br />
                    &copy; 2024 watchover.pro. All rights reserved.
                    </p>
                </div>
            </Fragment>
        );
    }
}

export default withNavigate(Recommandation)